
import {Options, Vue} from 'vue-class-component';
import ProductionTemplate from "@/components/ProductionTemplate.vue";
import ProductIntroduceData from "@/types/ProductIntroduceData";

@Options<MetaLaw>({
  components: {
    ProductionTemplate
  },
  props: {}
})
export default class MetaLaw extends Vue {
  introduce:ProductIntroduceData = {
    name:'MetaLaw类案分析',
    profile:'精准类案，一键直达',
    buttons:{
      experience:'https://meta.law',
      videoIntroduce:''
    },
    features:[
      {
        svgIconName: 'trend',
        feature:{
          title:'法律垂直大语言模型',
          desc:[
            '区别于传统的法律数据库，无需总结关键词，AI会自动完成自然语言到法言法语的转换',
            '通过上百亿级别参数训练，学习超过1.5亿篇裁判案例、200万篇法律法规，法律问题回复更加靠谱'
          ]
        }
      },
      {
        svgIconName: 'academic',
        feature:{
          title:'法律问题分析',
          desc:[
            '法律问题口语化输入，案件分析思路获取，精准推送类案',
            '全网实时知识经验一键获取，获取信息更加直观、高效'
          ]
        }
      },
      {
        svgIconName: 'convenient',
        feature:{
          title:'辅助提效',
          desc:[
            '问题联想、思维导图帮助专业人士快速提效',
            '快速定位相关案例，类案分析报告一键导出',
          ]
        }
      }
    ],
    psText:'使用开通'
  }
}
